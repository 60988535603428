import React, { useEffect, useState } from "react";
import { Navbar, Row, Image, OverlayTrigger, Popover } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { userlogo } from "../../helpers/formatter";
import constants from "../../helpers/constants";
import logoutarrow from "../../assets/logoutarrow.svg"
import DownArrow from "../../assets/DownArrow.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";


export default function Header(props) {
  const MySwal = withReactContent(Swal);
   const navigate = useNavigate();
  const { instance } = useMsal();
  const location = useLocation();
  console.log(location.pathname);
  console.log(location);
  let MenuName = "";
  if (location.pathname == "/") {
    MenuName = "Dashboard"
  }
  if (location.pathname == "/usermaster") {
    MenuName = "Maintain Users"
  }
  if (location.pathname == "/roleauthorization") {
    MenuName = "Role Master"
  }
  if (location.pathname == "/itemmaster") {
    MenuName = "Item Code Master"
  }
  if (location.pathname == "/projectmaster") {
    MenuName = "Project Master"
  }
  if (location.pathname == "/projectcosttype") {
    MenuName = "Project Cost Type Master"
  }
  if (location.pathname == "/currencyexchangerate") {
    MenuName = "Currency Exchange Rate"
  }
  if (location.pathname == "/productcost") {
    MenuName = "Product Cost Master"
  } if (location.pathname == "/sodetails") {
    MenuName = "So Details Master"
  }
  if (location.pathname == "/sosummary") {
    MenuName = "So Summary Master"
  }
  if (location.pathname == "/workflowmatrix") {
    MenuName = "Workflow Matrix Master"
  }
  if (location.pathname == "/businesscode") {
    MenuName = "Business Code Master"
  }
  if (location.pathname == "/simulatepoc") {
    MenuName = "Simulate POC"
  }
  if (location.pathname == "/estimatedcost") {
    MenuName = "Maintain Project Estimated Cost"
  }
  if (location.pathname == "/actualprojectcost") {
    MenuName = "Maintain Actual Project Cost"
  }
  if (location.pathname == "/jvgeneration") {
    MenuName = "JV Generation"
  }
  if (location.pathname == "/quarterlyestimate") {
    MenuName = "Quarterly Estimate Confirmation"
  }
  



  function handleLogout(instance) {
    navigate('/');    
    sessionStorage.clear();
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }


  const colors = ['#d9e3ee', '#f3d7d7', '#ebe4d5', '#d7f4f4', '#e0d5f1'];
  const fontcolors = ['#1A78E5', '#C64441', '#EDA70E', '#23B3B3', '#5D00F8'];
  const [randomColor, setRandomColor] = useState('');
  const [randomFontColor, setRandomFontColor] = useState('');
  

  useEffect(() => {
    const assignColor = Math.floor(Math.random() * colors.length);
    const selectedColor = colors[assignColor];
    setRandomColor(selectedColor);
    const findFontColor = colors.findIndex(el => el === selectedColor);
    const selectedFontColor = fontcolors[findFontColor];
    setRandomFontColor(selectedFontColor);
  }, []);

  const logoStyle = {
    background: `${randomColor} 0% 0% no-repeat padding-box`,
    color: `${randomFontColor}`
  };

  const handleSubmit = async () => {

    let confirmationMessage = "Are you sure you want to Logout ?";
    let title = "Logout";

    MySwal.fire({
      title: title,
      html: confirmationMessage,
      ...constants.CONFIRMATION_BOX_CONFIG
    }).then(async (selection) => {
      if (selection.isConfirmed) {
        handleLogout(instance);
      }
    });

  }
  return (
    <>
         <Navbar bsPrefix="header">
        <div className="sidebar-head-header">
          <List className="head-toggle" onClick={() => { props.setSidebar(props.sidebar === "closed" ? "open" : "closed") }}></List>
        </div>
        {/* changes by bharshal */}
        <div className="app-name"> {MenuName}</div>
        <div className="user-section">
          <OverlayTrigger trigger="click" placement="bottom-end" rootClose overlay={
            <Popover className="profile"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <div className="user-section">
                <div className="user-logo">
                  {userlogo(sessionStorage.getItem("UserId"))}
                </div>
                <div className="user-info">
                  <Row>
                    <span className="user-name">{sessionStorage.getItem("UserId")}</span>
                  </Row>
                  <Row>
                    <span className="user-email">{sessionStorage.getItem("Email")} </span>
                  </Row>
                </div>
                <Image src={logoutarrow} onClick={() => {
                  handleSubmit()

                }}></Image>

              </div>
            </Popover>}>
            <div className="user-logo" >
              {/* removed the function and directly applied the css */}
              {userlogo(sessionStorage.getItem("UserId"))}
            </div>
          </OverlayTrigger>
        </div>
      </Navbar>


    </>
  );
}
